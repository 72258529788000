@import "./../colors.scss";



    .kt-language-select-label-wrapper {
        display: flex;
        align-items: center;

        .kt-language-select-label {
            margin-left: 5px !important;
        }

        .MuiSvgIcon-root {
            margin-left: 20px;
            width: 20px;
            height: 20px;
            &:hover {
                color: $primary-color;
            }
        }

        & i {
            margin-right: 5px;
        }
    }




