.kt-docs-page {

    .MuiToolbar-root {
        display: grid;
        grid-template-columns: auto auto auto;

        .kt-header-center-label {
            text-align: center;
        }
    }

    .kt-languages {
        display: flex;

        align-items: center;
        justify-self: end;
    }

    .kt-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .kt-document-card {
            width: 320px;
            cursor: pointer;
            color: rgb(97, 97, 97);
            border: 1px solid transparent;
            margin-bottom: 40px;

            &:hover {
                border: 1px solid $primary-color;
            }

            i {
                font-size: 80px;
                margin-top: 20px;
                color: rgb(138, 169, 219);
            }

            .MuiCardActions-root {
                justify-content: center;
            }

            .kt-signed {
                color: rgb(22, 160, 22);
            }
        }
    }



    @media screen and (max-width: 1048px) {

        .MuiToolbar-root {
            h6, .kt-header-center-label {
                font-size: 12px !important;
            }

            .kt-header-center-label {
                margin: 0 5px;
            }
        }


        .kt-languages {

            .kt-language-buttons {
                display: none;
            }
        }

    }


}