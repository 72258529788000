.kt-logItem-list {
    position: absolute;
    width: 200px;
    right: 0;
    top: 106px;
    bottom: 10px;
    min-height: 170px;

    display: flex;
    flex-direction: column;

    margin: 0;

    padding: 10px 0 10px 0;

    overflow-y: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */



    .kt-logItem {
        z-index: 0;
        position: relative;
        display: grid;
        grid-template-columns: 35px auto;

        width: 190px;
        height: fit-content;

        padding: 4px;
        margin: 5px 5px 5px 5px;
        border: 1px solid;
        border-radius: 4px;

        color: $toaster-color;

        font-size: 11px;

        border-color: lightgrey;
        background-color: $toaster-background-color;

        &.kt-clickable {
            cursor: pointer;
            &:hover {
                color: $primary-color;
            }
        }

        &.kt-logItem-bot {
            border-left: 4px solid $secondary-color;
        }

        &.kt-logItem-patient {
            border-left: 4px solid $primary-color;
        }


        .kt-left-column {
            .kt-time {
                font-weight: 500;
            }
        }
    }

}