@import "./../colors.scss";

.kt-disabled-page {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: self-start;
    justify-content: center;
    color: $secondary-color;

    .kt-wrapper {
        display: grid;
        text-align: center;
        row-gap: 10px;
        margin-top: 50px;

        .kt-logo {
            width: 100px;
            height: 100px;
            margin: 0 auto 10px auto;
            border: none;

            &.kt-margin-bottom {
                margin-bottom: 30px;
            }
        }

        a {
            margin-top: 20px;
            text-decoration: none;
            color: $primary-color;
        }

        h1 {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 1.3em;
            display: block;

        }
    }


}