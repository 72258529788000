@import "colors.scss";

.kt-resource-filter {
    font-size: 15px;

    .MuiAccordion-root {
        font-size: 15px;

        &.Mui-expanded {
            margin: 0;
        }

        .MuiAccordionSummary-root {
            min-height: 30px;
            height: 30px;
            background-color: $secondary-color;
            color: white;
            border-bottom: 1px solid white;
            border-radius: 4px;

            .MuiAccordionDetails-root {
                padding: 8px 8px 8px;
            }

            .MuiIconButton-root {
                color: white;
            }

            i {
                width: 21px;
            }
        }

        &.kt-no-border-bottom {
            .MuiAccordionSummary-root {
                border-bottom: none;
            }
        }
    }

    .kt-resource-filter-caption {
        padding: 0 0 0 10px;
        background-color: $secondary-color;
        color: white;;
    }

    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            display: grid;
            grid-template-columns: 40px auto 18px;
            border-top: 1px solid $grid-line-color;
            min-height: 38px;
            align-items: center;
            width: 100%;

            &.kt-speciality-li {
                grid-template-columns: 25px auto 40px;
            }

            &.kt-two-columns-li {
                width: 100%;
                grid-template-columns: auto 40px;
            }

            .kt-speciality-color-rect {
                width: 15px;
                height: 15px;
                border: 1px solid grey;
                border-radius: 3px;
            }

            .kt-avatar {
                //margin-left: 10px;
            }

            &:first-child {
                border-top: none;
            }

            label:hover {
                cursor: pointer;
                color: $primary-color;
            }

            input {
                cursor: pointer;
            }

            .kt-resource-filter-button {
                display: inline-block;
                cursor: pointer;
                display: grid;
                align-items: center;
            }

            .kt-resource-filter-label {
                overflow-wrap: anywhere;
            }

            ul{
                border-top: 1px solid $grid-line-color;

                li{
                    border-top: 1px solid $grid-line-color;
                    padding: 0 0 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .kt-resource-filter {
        ul {
            width: 100%;

            li {
                .kt-resource-filter-button {
                    font-size: 30px;
                }
            }
        }
    }
}