$play-button-size: 40px;
$play-button-border-size: 2px;

.kt-video-player-ctrl {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;

    &:hover .kt-play-button {
        border: $play-button-border-size solid $secondary-color;
    }

    .kt-play-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: calc(50% - $play-button-border-size - $play-button-size / 2);
        left: calc(50% - $play-button-border-size - $play-button-size / 2);
        cursor: pointer;
        pointer-events: none;

        background-color: white;
        border-radius: 50%;
        width: $play-button-size;
        height: $play-button-size;
        border: $play-button-border-size solid transparent;

        &.kt-hide {
            opacity: 0;
        }

        i {
            opacity: 1;
            font-size: calc($play-button-size - $play-button-border-size);
            color: $secondary-color;
        }
    }

    video {
        cursor: pointer;
        border-radius: 10px;
        width: 100%;
        max-height: 600px;
        margin: auto;
    }

}

.kt-video-group-player-ctrl {

    //border: 1px solid red;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgb(245, 245, 245);
    padding: 0;

    .kt-language-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 5px;
        padding: 10px;
        background-color: white;


        .kt-language-button {
            display: flex;
            justify-content: center;
            width: 35px;
            height: 20px;
            border: 2px solid transparent;
            border-radius: 3px;
            overflow: hidden;
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            padding: 5px;

            &.kt-no-padding {
                padding: 0;
            }
        }

        .kt-selected {
            border: 2px solid $secondary-color;
        }
    }

}



@media screen and (max-width: 980px) {
    .kt-video-player-ctrl {

        video {
            width: 100%;
            max-height: 400px;
        }

    }
}