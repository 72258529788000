.kt-image-upload-ctrl {

    display: flex;
    align-items: center;

    img {
        margin-right: 10px;

        border: 1px solid grey;

        min-height: 200px;

        object-fit: contain;
    }

}