@import "colors.scss";

.fc {
    table {

        .fc-timegrid-col,
        .fc-day,
        .fc-scrollgrid-section {

            padding: 0;

        }
    }
}

.fc-button {
    font-size: 0.8em;
}

.fc-toolbar {

    //z-index: 10;

    &.fc-header-toolbar {
        padding: 10px;
        background-color: $calendar-top-toolbar-bg-color;
        margin-bottom: 0 !important;

        // range where 12px is the minimum size at the smallest viewport width of 300px and where 16px is the maximum size at the largest viewport width of 1600px:
        font-size: 12px; //calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));

        .fc-toolbar-title {
            font-size: 1.2em;
        }
    }

}

.fc-non-business {
    background: repeating-linear-gradient(45deg,
            $background-event-background-color-shaded,
            $background-event-background-color-shaded 5px,
            $background-event-background-color 5px,
            $background-event-background-color 10px) !important;
}

.fc-view-harness {
    overflow: hidden;
}

.fc-timegrid-event-harness {
    overflow: hidden;
}

.fc-event {
    margin-right: 2px;
    //border: none;
    //border-left: 4px solid !important;
    background-color: $appointment-background-color !important;
    color: $appointment-font-color !important;
    overflow: hidden !important;

    &.kt-low-opacity {
        opacity: 0.5;
    }

    &.kt-ghost-opacity {
        opacity: 0.2;
    }

    &.kt-line-through {
        background-color: red !important;
    }
}

.fc-timegrid-event {
    background-color: $appointment-background-color !important;
    color: $appointment-font-color !important;
    border-left-width: 4px !important;
    border-left-style: solid;

    border-radius: 4px !important;
    //border-left-color: inherit;

    border-top-color: $appointment-border-color !important;
    border-right-color: $appointment-border-color !important;
    border-bottom-color: $appointment-border-color !important;

    &.kt-new-patient {
        border-width: 2px;
        border-top-color: $appointment-border-color-new-patient !important;
        border-right-color: $appointment-border-color-new-patient !important;
        border-bottom-color: $appointment-border-color-new-patient !important;
    }

    .kt-needs-confirmation {
        color: red;
    }
}



.fc-head {
    font-size: 13px;
}

.fc-view-container {
    table tbody tr {
        td {
            cursor: pointer;
        }

        td.fc-time {
            cursor: default;
        }
    }
}


.kt-absence {
    background-color: $appointment-background-color-absence !important;
}

.kt-appointment-wrapper {

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    color: $appointment-font-color !important;
    display: grid;
    grid-template-rows: 20px auto;
    margin-top: -2px;
    padding-left: 3px;

    .kt-documents-status {
        position: absolute;
        width: 10px;
        height: 10px;
        right: -5px;
        bottom: -5px;
        transform: rotate(45deg);

        &.kt-docs-complete {
            background-color: $docs-complete-color;
        }

        &.kt-docs-sent {
            background-color: $docs-sent-color;
        }

        &.kt-docs-incomplete {
            background-color: $docs-incomplete-color;
        }

    }

    &.kt-shaded {
        background: repeating-linear-gradient(45deg,
                $appointment-background-color-shaded,
                $appointment-background-color-shaded 10px,
                $appointment-background-color 10px,
                $appointment-background-color 20px);
    }


    .kt-appointment-head {

        .kt-appointment-title {
            display: inline-block;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            strong {
                //color: rgb(69, 69, 69);
            }
        }

        .kt-appointment-icons {
            position: absolute;
            top: 3px;
            right: 3px;
            font-size: 10px;

            div {
                display: inline;
                margin-left: 2px;
            }

            .fa-user-plus {
                // color: rgb(14, 129, 223);
            }

            .fa-star {
                // color: gold;
            }
        }
    }

    .kt-appointment-quick-buttons {
        font-size: 10px;
        display: inline-block;

        button {
            padding: 2px;
            border: none;
            margin: 1px;
            background-color: $secondary-color;
            color: white;
            width: 16px;
            //height: 16px;
            border-radius: 4px;
            cursor: pointer;
        }
    }

    .kt-appointment-details {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        margin-top: -5px;

        .fa-star {
            font-size: 10px;
            margin-right: 5px;
        }
    }

}

.fc-dayGridMonth-view {
    .fc-day-today {
        .fc-daygrid-day-frame {
            border: 1px solid $primary-color !important;
        }
    }
}

.fc-day-today {
    background-color: rgba(42, 103, 225, 0.05) !important;

    &.fc-col-header-cell div {
        background-color: $secondary-color;

        color: white;
        font-weight: 400;
        border-bottom: 2px solid $primary-color;

        a {
            color: white;
        }
    }

}

.fc-resourceTimeGridDay-view {
    .fc-day-today {
        background-color: white !important;
    }
}

.kt-publicHoliday {
    padding-left: 5px;

    background-color: $holiday-color;

    .kt-title {
        margin-left: 3px;
    }
}

.fc-button-active {
    border-bottom: 2px solid $primary-color !important;
}

.fc-list-table {
    tr.fc-list-event:hover {
        color: $font-color !important;
        background-color: $appointment-background-color-shaded !important;
        cursor: pointer;
    }
}


.fc-daygrid-event-dot {
    display: inline-block !important;
}