@import "colors.scss";


.kt-smsEditor{
    
    .MuiDialogContent-root {
        overflow: hidden;
    }

    .kt-insert-button {
        margin-top: 10px;
    }
    
    .kt-dialogSmsText {
        margin-top: 20px;
    }

    .kt-character-count-label {
        font-family: Helvetica, Arial, sans-serif;

        &.kt-error {
            color: red;
        }
    }

    .kt-smsPreviewField-wrapper {
        position: relative;
        height: 0;
        width: 0;

        .kt-smsPreviewField {
            text-align: left;
            line-height: 16px;
            position: absolute;
            top: -207px;
            left: 39px;            
            min-height: 40px;
            padding: 10px;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
            background-color: $speech-bubble-color;
            border-radius: 10px;

            overflow-wrap: break-word;

            white-space: pre-line;
        }

        .bubble-bottom-left:before {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 12px solid $speech-bubble-color;
            border-right: 6px solid transparent;
            border-top: 6px solid $speech-bubble-color;
            border-bottom: 12px solid transparent;
            left: 0;
            bottom: -10px;
          }
    }
    
}