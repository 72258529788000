@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
            font-size: 16px;
        }
    }


.kt-bookingPanel {

    //padding: 10px 0 0 0;

    .kt-overlay{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(128,128,128,0.6);
        z-index: 10;
    }

    &>.kt-panel {
        border: 1px solid $primary-color;
    }

    .kt-panel {

        z-index: 20;

        padding: 0;

        h3 {
            padding-top: 3px;
        }

        .kt-panel-icon {
            i.fal {
                margin-top: 0;
            }
        }
    }

    .kt-with-icon {
        grid-template-columns: 50px auto;
    }


    .kt-btn-big {
        z-index: 20;

        margin: 10px auto 10px auto;

        i {
            margin-right: 5px;
        }
    }

    @keyframes kt-expand-bottom {
        0%   {
            opacity: 0;
            -webkit-opacity: 0;
            max-height: 50px;
            -webkit-max-height: 50px;
            overflow: hidden;
        }
        100% {
            opacity: 1;
            -webkit-opacity: 1;
            max-height: 1000px;
            -webkit-max-height: 1000px;
        }
    }



    .kt-expand-bottom {
        animation-name: kt-expand-bottom;
        animation-duration: 1s;
        animation-delay: 0;
        //box-shadow: 2px 2px 5px grey;

        .kt-bookingPanel-content {

            padding: 20px 20px 20px 0;

            .kt-bookingStep {


                .kt-progressColumn {
                    display: grid;

                    grid-template-rows: 25px auto;


                    .kt-booking-progress-icon {
                        justify-self: center;
                        line-height: 26px;
                        width: 25px;
                        height: 25px;
                        background-color: $primary-color;
                        color: white;
                        border-radius: 50%;

                        font-weight: 800;
                        text-align: center;
                    }

                    .kt-bookingStep-line {
                        justify-self: center;

                        align-self: stretch;

                        margin-top: 2px;
                        margin-bottom: -8px;
                        width: 2px;

                        background-color: lightgray;

                    }

                    .kt-booking-step-selected {
                        background-color: rgb(59, 202, 2);
                    }
                }


            }


            .kt-selected-user {
                background-color: $primary-color;
                color: white;
                padding-left: 20px;
                padding-right: 5px;
                &:hover {
                    color: white;
                }
            }

            .kt-users {
                margin-bottom: 20px;;
            }

            h3 {
                font-size: 1em;
                font-weight: 700;
                display: block;
                margin: 0;
            }

            select {
                height: 50px;
                margin-top: 5px;
                margin-bottom: 20px;
                margin-right: 10px;
                position: relative;
                width: 95%;
                background-color: white;
            }

            .kt-patient-info {
                width: 95%;
            }

            .kt-video-wrapper {
                width: 95%;
                position: relative;
                height: 0;
                padding-bottom: 56.25%;

                .kt-video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }


        }
    }


}


