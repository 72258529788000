.kt-opening-hours {    
    font-size: 0.9em;
    margin: auto;

    .kt-day-wrapper {
        display: grid;

        grid-template-columns: auto;

        .kt-day {
            margin-bottom: 10px;

            border: 1px solid $control-color;
            border-radius: 3px;;
            padding: 5px;
            min-height: 100px;

            .MuiTextField-root {
                margin-right: 10px;
            }

            .kt-day-label {
                font-weight: bold;
            }

            label {
                min-width: auto !important;
            }

            .kt-hours-wrapper {
                
                .kt-time-span {
                    display: grid;
                    grid-template-columns: auto 16px;

                    border: 1px solid lightgray;
                    padding: 5px;
                    margin-bottom: 5px;

                    i {
                        align-self: center;
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    .kt-create-new {
        display: grid;
        grid-template-columns: auto 100px auto;
        column-gap: 3px;

        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid lightgray;

        input[type=text], select {
            width: auto !important;            
        }
    }
}