﻿@import "colors.scss";

.kt-datePicker {
    
    .kt-datePicker-input-wrapper {
        display: grid;
        grid-template-columns: auto 16px;
        grid-column-gap: 5px;
        border: 1px solid $date-picker-light-color;
        color: $input-text-color;
        background-color: white;
        line-height: inherit;
        height: 100%;

        &:hover {
            cursor: pointer;
        }
        span{
            margin-left: 11px;
            //line-height: inherit!important;
        }
        i {
            align-self: center;
            justify-self: end;
            margin-right: 5px;
        }
    }

    .kt-datePicker-calendar {
        height: 193px;
        border-bottom: 1px solid $grid-line-color;


        .kt-datePicker-header {
            display: grid;
            grid-template-columns: 24px auto 60px 24px;
            border-bottom: 1px solid $grid-line-color;
            text-align: center;
            font-size: 14px;
            font-weight: 800;

            line-height: unset;

            div {
                margin:0;
                padding: 3px 10px 3px 10px;

                &:hover {
                    background-color: $row-hover-color-light;
                    cursor: pointer;
                }
            }

            .kt-datePicker-nav-button {
                width: 24px;
                i {
                    line-height: unset;
                }
            }
        }

        .kt-datePicker-days {
            .kt-datePicker-body {
                font-size: 14px;
                display: grid;
                grid-template-columns: 20px auto;
                grid-template-areas: "weeknumbers weekdays" "weeknumbers days";

                .kt-weekdays {
                    grid-area: weekdays;
                    display: grid;
                    grid-template-columns: repeat(7, auto);
                    font-weight: 700;
                }

                .kt-days-wrapper {
                    grid-area: days;
                    display: grid;
                    grid-template-columns: repeat(7, auto);

                    div:hover {
                        background-color: $row-hover-color-light;
                        cursor: pointer;
                    }

                    .kt-days-light {
                        color: $date-picker-light-color;
                    }

                    div.kt-days-today {
                        color: $primary-color;
                        font-weight: 900;
                    }

                    div.kt-days-selected {
                        color: white;
                        background-color: $secondary-color;
                    }
                }

                .kt-weeknumbers {
                    grid-area: weeknumbers;
                    display: grid;
                    grid-template-rows: repeat(6, auto);
                    border-right: 1px solid $grid-line-color;
                    color: $date-picker-light-color;
                }

                div {
                    padding: 1px;
                    text-align: center;
                }
            }
        }

        .kt-datePicker-months-years {
            display: grid;

            .kt-datePicker-header {
                grid-template-columns: 24px auto 24px;
            }

            .kt-datePicker-body {
                display: grid;
                grid-template-columns: auto auto auto;
                font-size: 14px;
                justify-items: center;
                align-items: center;

                div {
                    text-align: center;
                    margin: 1px;
                    height: 40px;
                    padding: 8px 15px 8px 15px;
                }

                .kt-datePicker-years:first-child,
                .kt-datePicker-years:last-child {
                    color: $date-picker-light-color;
                }

                div:hover,
                .kt-datePicker-years:hover {
                    border-radius: 3px;
                    background-color: $secondary-color-light;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        &.kt-datePicker-dropdown {
            width: 270px;
            position: absolute;
            background-color: white;
            border: 1px solid $date-picker-border-color;
            box-shadow: 1px 1px 5px grey;
            z-index: 99999;
            font-size: 12px;
        }
    }
}
