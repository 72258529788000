@import "colors.scss";

.kt-multiAppointmentCtrl {

    .kt-header {

        .kt-filter {
            label {
                margin:0;
                padding: 0;                
            }

            select {
                margin-left: 5px;
            }
        }

        .kt-visitMotives {            
            
            margin-top: 10px;

            .kt-tab {
                background: white;
                &:hover {
                    color: $primary-color;
                }
            }

            .kt-selected {
                background: $secondary-color;
                &:hover {
                    color: white;
                }
            }

            .kt-speciality-name-wrapper {

                display: grid;
                grid-template-columns: auto auto;
                background-color: $secondary-color;
                border-bottom: 1px solid $secondary-color-light;
                
                .kt-speciality-name {
                    padding-left: 10px;
                    padding-top: 5px;
                    color: white;                    
                }

                .kt-search-field {                    
                    text-align: right;
                    font-size:0.9em;
                    
                    input {
                        height: 18px;
                        margin-right: 10px;
                        margin-top: 5px;
                        border: none;
                        border-radius: 0;
                        background-color: $secondary-color-light;
                        color:white;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.698);
                        }

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .kt-visitMotives-container {
                padding: 5px;                
                background-color: $secondary-color;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
                height: 190px;
                overflow: auto;

                .kt-visitMotive {
                    height: 50px;
                    border-left: 5px solid white;
                    border-top: 1px solid $font-color;
                    border-right: 1px solid $font-color;
                    border-bottom: 1px solid $font-color;

                    color: $font-color;
                    background-color: white;
                    padding: 3px;
                    
                    border-radius: 5px;
                    margin: 5px;
                    cursor: pointer;
                    font-size: 0.8em;

                    display: grid;
                    
                    align-content: center;


                    .kt-motive-description {
                        display: grid;
                        text-align: center;
                        align-content: center;
                        margin-right: 5px;
                    }

                }
            }
        }
    }

    .kt-body {
        padding-top: 20px;
        border: 1px solid $control-color;
        background-color: white;
        overflow-x: hidden;

        .kt-chain-wrapper {
            
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(270px, 270px));
            min-height: 150px;

            align-items: flex-end;

            overflow-x: hidden;

            .kt-drop-area-first-empty {                
                padding-left:10px;
                height: 100%;
                width: 300px;
                color:red;
                padding-top: 60px !important;
            }


            .kt-chain-column {
                display: grid;
                align-items: center;
                
                height: 100%;

                .kt-chainItem-wrapper {

                    margin-bottom: -10px;
                    &:last-child {                    
                        margin-bottom: 20px;
                    }

                    display: grid;
                    align-items: center;

                    grid-template-columns: 200px 50px;                
                    column-gap: 10px;

                    .kt-chainItem {

                        grid-column: 1 /1;
                        grid-row: 2/2;

                        color: $font-color;
                        min-height: 70px;
                        border: 1px solid rgb(105, 105, 105);
                        background-color: rgb(255, 255, 255);
                        
                        box-shadow: 10px 10px 15px silver;

                        border-radius: 5px;
                        margin: 5px;
                        cursor: pointer;
                        font-size: 0.8em;

                        display: grid;
                        grid-template-columns: 50px auto 10px;
                        align-content: center;

                        .kt-icon {
                            width: 40px;
                            min-height: 70px;                        
                            margin-left: 10px;
                            display: grid;
                            align-content: center;
                            
                            .kt-number-label{
                                color: white;
                                text-align: center;
                                font-size: 30px;
                                z-index: 9;

                                &.kt-small {
                                    font-size: 20px;
                                }
                            }

                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        .kt-motive-description {
                            display: grid;
                            align-content: center;
                            
                            div {
                                text-align: center;
                                text-overflow: ellipsis; 
                                overflow: hidden;
                                margin: 5px;
                            }
                        }

                        .kt-motive-close-button {                            
                            height: 20px;
                            line-height: 5px;                            
                            margin-left: -10px;
                            margin-top: 2px;
                            color: $font-color;
                            font-size: 15px;
                            padding: 4px;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        .kt-motive-descriptions {
                            display: grid;
                            align-items: center;

                            .kt-motive-description {
                                width: 120px;
                                height: 20px;
                                margin: 2px;

                                display: grid;
                                grid-template-columns: 5px 110px 20px;

                                .kt-motive-color {                                    
                                    margin:0;
                                    margin-left: -7px;
                                    width: 5px;
                                    height: 20px;                                    
                                }
                                .kt-motive-name {                                    
                                    margin:0;
                                    margin-left: 2px;
                                    white-space: nowrap;
                                    border-bottom: 1px solid lightgray;
                                }

                                .kt-motive-close-button {
                                    position: relative;
                                    margin:0;                                    
                                }
                            }
                        }

                        
                    }

                    .kt-drop-area {
                        width: 50px;
                                            
                        text-align: center;
                        display: grid;
                        align-content: center;
                        // border: 1px solid lightgrey;

                        &.kt-border {
                            border: 1px dashed gray;
                        }
        
                        .kt-chain-arrow {
                            pointer-events: none;
                            font-size: 40px;
                        }

                        .kt-chain-min-days{
                            font-size: 10px;
                        }

                    }

                    
                    .kt-drop-right {
                        grid-column: 2 /2;
                        grid-row: 2/2;

                    }

                    .kt-drop-top {
                        grid-column: 1 /1;
                        grid-row: 1/1;
                        
                        margin-bottom: -5px;

                        height: 30px;
                        width: 100%;;
                    }

                    .kt-drop-bottom {
                        grid-column: 1 /1;
                        grid-row: 3/3;

                        margin-top: -5px;

                        height: 30px;
                        width: 100%;
                    }

                }
            }

            .kt-chain-column:last-child, .kt-chainItem-wrapper:not(:last-child) {
                
                width: 200px;

                .kt-drop-right {
                    width: 200px;
                    min-height: 70px;

                    &>div{
                        display: none;
                    }
                }
            }

            
        }
    }

    .kt-footer {

    }
}