$fadeInDuration: 1s;
$fadeOutDuration: 2s;


.fade-in {
    animation: fadeIn ease $fadeInDuration;
    -webkit-animation: fadeIn ease $fadeInDuration;
    -moz-animation: fadeIn ease $fadeInDuration;
    -o-animation: fadeIn ease $fadeInDuration;
    -ms-animation: fadeIn ease $fadeInDuration;
}

.fade-out {
    animation: fadeOut ease $fadeOutDuration;
    -webkit-animation: fadeOut ease $fadeOutDuration;
    -moz-animation: fadeOut ease $fadeOutDuration;
    -o-animation: fadeOut ease $fadeOutDuration;
    -ms-animation: fadeOut ease $fadeOutDuration;

    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}




@keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0; position: absolute; top: -2000px;}
}

@-moz-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0; display: none;}
}

@-webkit-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0; display: none;}
}

@-o-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0; display: none;}
}

@-ms-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:01; display: none;}
}





.kt-toaster {
    position: absolute;
    width: 300px;
    height: 100%;
    right:0;
    z-index: 999;

    pointer-events: none;

    .kt-toast {
        margin: 10px;
        padding: 15px 20px 15px 20px;

        border-radius: 5px;

        display: grid;
        grid-template-columns: 35px auto;

        text-align: center;

        align-content: center;
        column-gap: 10px;

        i {
            font-size: 30px;
        }

        &.kt-success {
            color: #155724;
            background-color: #d4edda;
        }

        &.kt-info {
            color: #0c5460;
            background-color: #d1ecf1;
        }

        &.kt-warning {
            color: #856404;
            background-color: #fff3cd;
        }

        &.kt-danger {
            color: #721c24;
            background-color: #f8d7da;
        }

        &.kt-hide {
            display: none;
        }
    }
}