.kt-signature-dialog {

    .kt-signature-wrapper {
        position: relative;
        width: 700px;
        height: 400px;

        .kt-signature {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid grey;
        }
    }

    // mobile
    @media screen and (max-width: 700px) {

        .kt-signature-wrapper {
            width: 550px;
            height: 130px;
        }
    }

    // mobile
    @media screen and (max-width: 400px) {

        .kt-signature-wrapper {
            width: 270px;
            height: 180px;
        }
    }

}