.kt-creditsPanel {

    width: 200px;
    height: 300px;
    border: 1px solid lightgray;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 5px 5px 15px lightgray;

    big {
        color: $primary-color;
        font-size: 25px;
    }

    .kt-creditsPanel-icon {
        font-size: 50px;
    }
    
    &:hover {
        border-color: $primary-color;
    }
}