.kt-image-input-ctrl {

    .kt-form-ctrl-wrapper {
        display: flex;
        align-items: center;

        button {
            margin-left: 10px;
        }
        
    }

}

.kt-avatar-editor-wrapper{
    margin: auto;    
    text-align: center;    
    width: 300px;

    input {
        display: none;
    }
    
}