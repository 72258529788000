.kt-documents-tab {

    .kt-delete-button :hover {
        color: red;
    }

    .kt-form-section {
        //margin-bottom: 10px;
    }

    .kt-documents-transfer-list {
        display: flex;
        justify-content: space-between;
    }

    .kt-documents-transfer-list-column {
        width: 300px;

        .kt-form-section {
            height: calc(100vh - 400px);
            min-height: 300px;
            overflow-y: auto;
        }

        h5 {
            margin-bottom: 5px;
        }

        .kt-drop-target {

            &.kt-drop-active {
                border: 1px solid $primary-color;
                box-shadow: 0 0 5px $primary-color;
                transition: 200ms;
            }
        }
    }

    .kt-draggable-document-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: white;
        border: 1px solid $appointment-border-color;

        margin-bottom: 5px;
        padding: 10px;

        border-left: 4px solid $secondary-color;
        border-radius: 4px;


        .kt-doc-name {
            text-align: center;
        }
        i.fa-notes-medical{
            font-size: 30px;
            margin-right: 10px;
        }

        i.fa-exclamation, i.fa-paper-plane {
            margin-left: 5px;
            font-size: 14px;
            color: $primary-color;
        }
        i.fa-paper-plane {
            color: $docs-sent-color;
        }

        &.kt-dragging-active {
            color: $primary-color;
            border-color: $primary-color;
            box-shadow: 5px 5px 5px lightgrey;
        }
    }

    .kt-send-button-wrapper {
        display: flex;
        flex-direction: column;

        .kt-send-button {
            width: auto;
            margin: auto;
            margin-top: 30px;
            font-size: 18px;
            height: 50px;
        }
    }

    .kt-content-rectangle {
        border-left-color: $secondary-color;
        background-color: white;
        color: $secondary-color !important;
        p {
            color: $secondary-color !important;
        }
    }

    .kt-document-details-button {
        color: gray;
        width: 30px;
        cursor: pointer;
        text-align: center;
        margin-left: 15px;

        &:hover {
            color: $primary-color;
        }
    }
}


#kt-hidden-documents-qrcode-wrapper {
    position: absolute;
    top: -400px;
    left: -400px;
}