.kt-settings-side-menu {

    overflow-y: auto;
    
    a {
        text-decoration: none;
    }

    .kt-settings-side-menu-item {
        display: grid;
        grid-template-columns: 45px auto 35px;
        align-items: center;
        height: 50px;
        cursor: pointer;
        margin: 10px;
        border-radius: 8px;
        font-weight: 600;
        
        .kt-menu-icon {
            background-color: white;
            font-size: 12px;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
        }

        i {
            text-align: center;
        }

        &:hover {
            background-color: rgb(245, 245, 245);
            color: $secondary-color;
        }

        &.kt-active {
            background-color: $primary-color rgb(245, 245, 245);
            color: $secondary-color;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

            .kt-menu-icon {
                color: $primary-color;
                box-shadow: none;
            }
        }

    }

    .kt-settings-side-submenu-item {
        display: flex;
        align-items: center;
        height: 40px;
        cursor: pointer;
        margin: 10px;
        border-radius: 8px;
        padding-left: 35px;

        &:hover,
        &.kt-active {
            background-color: rgb(245, 245, 245);
            color: $secondary-color;
        }
    }
}