
.kt-dashboard-page {

    overflow-y: auto !important;
    height: 100%;

    h2 {
        margin-bottom: 0;
    }

    .MuiTabs-root {
        margin-bottom:5px;
    }
    .margin-bottom {
        margin-bottom: 20px;
    }
    .card {
        background-color: white;
            
        border: 1px solid $control-color;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        height: 100%;

        .chartjs-render-monitor {
            padding: 10px;
        }

        .kt-red-rating {
            color: rgb(255, 58, 58);
        }
        .kt-orange-rating {
            color: rgb(255, 166, 50);
        }
        .kt-yellow-rating {
            color: rgb(208, 255, 0);
        }
        .kt-green-rating {
            color: rgb(121, 226, 1);
        }

        .kt-stars-cell {
            width: 100%;
            text-align: right;

            span {
                margin-left: 5px;
            }
        }

        &.kt-rating-docs {
            padding: 15px;
            
            h3 {
                color: $font-color;
            }

            .kt-number-of-ratings {                
                font-size: 18px;
            }
           
            .kt-rating-details {
                margin-top: 10px;
                display: grid;
                grid-template-columns: 150px 40px;

                i {
                    color: orange;
                }
            }

            .kt-doctor-ranking {

                .kt-doctor-ranking-row {
                    display: grid;
                    grid-template-columns: 45px auto 45px 80px;
                    align-items: center;
                    //justify-content: space-between;
                    //grid-column-gap: 30px;
                    
                    padding: 5px 0 5px 3px;

                    border-bottom: 1px solid $grid-line-color;

                    .kt-doctor-name {
                        flex-grow: 1;
                    }

                    .kt-big {
                        font-size: 22px;
                        .fa-star {
                            color: orange;
                        }
                    }
                }
            }
        }

        &.kt-rating-list {
            padding: 15px;
            
            .kt-list-wrapper {
                height: 500px;    
            }        
        }

        .kt-card-headline {
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .kt-chart-container {
        position: relative;        
        margin: 10px;

        h2 {
            margin-left: 10px;
            color: $secondary-color-light;
        }
    }

    .kt-map {
        //height: 100%;
        height: 300px;;
    }
    
    .kt-two-columns {
        width: 45%;
        display: inline-block;
    }
    

    padding: 0 20px 0 20px;

    a {
        text-decoration: none;
        color: $font-color;

        &:hover {
            color: $secondary-color;
            margin-left: 2px;
        }
    }

    .card-container {
        --column-count: 2;
        --spacing: 2rem;

        margin-bottom: 40px;
        //height: 80%;
        
        &.max-height {
            //height: 100%;
        }

        // Flexbox Fallback
        display: flex;
        flex-wrap: wrap;
              
        // Grid
        display: grid;
        grid-template-columns: repeat(var(--column-count), 1fr);
        grid-gap: var(--spacing);
        
        .kt-widgets-container {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            flex-basis: 100%;
            grid-column: 1 / -1;

        }

        .card {
            // By default, items span 2 columns
            flex: 1 1 50%;
            grid-column-end: span 2;
            //height: 100%;
            
            

            &.map {
                min-height: 300px;
            }

            background-color: white;
            
            border: 1px solid $control-color;

            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            .MuiPaper-elevation1 {
                box-shadow: none;
            }

            .card-head {
                display: grid;
                grid-template-columns: auto auto;
                padding: 6px 10px;
                font-size: 0.8em;
                font-weight: 900;
                border-bottom: 1px solid $primary-color;

                i {
                    color: $primary-color;
                    margin-right: 5px;
                }

                .kt-button-wrapper {
                    text-align: right;
                    div {
                        display: inline-block;
                    
                        button {                    
                            margin-top: 0;
                            margin-bottom: 0;
                            
                            i {
                                font-size: 10px;
                            }
                        }
                    }
                }
                
            }
            .card-body {
                padding: 20px 10px;

                // min-height: 400px;
                // max-height: 800px;
                bottom: 10px;
                overflow-y: auto;
                //height: 95%;

                & ul {
                    padding-left:0;
                    list-style: none;
                }

                table {
                    width: 100%;
                    user-select: none;
                    border-spacing: 0;

                    tbody {                        
                        tr {
                            td {                                
                                border-bottom: 1px solid #e9e9e9;
                                padding-left: 2px;
                                vertical-align: top;

                                &.kt-btn-table-column {
                                    width: 20px;
                                    text-align: right;
                                    
                                    i {
                                        font-size: 12px;
                                        border: 1px solid $font-color;
                                        padding: 3px;
                                        border-radius: 2px;
                                        //box-shadow: 1px 1px 1px grey;
                                    }
                                    i:hover{
                                        background-color: $font-color;
                                        color: white;
                                        border-color: white;
                                    }
                                
                                }
                            }
                            &:hover {
                                background-color: #f0f0f0;
                                cursor: pointer;
                            }
                        }
                        
                    }
                }
            }


            &.one {
                grid-column-end: span 1;
            }

            &.half {
                //flex-basis: calc(100% / var(--column-count));
                grid-column-end: span 2;
            }

            &.full {
                flex-basis: 100%;
                grid-column: 1 / -1;
            }


            &.card-quicklinks {                
                .card-body {                    
                    font-size: 1.5em;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto;
                    
                    align-items: center;
                    text-align: center;                    
                }
            }
            
        }
       
    }

    @media screen and (min-width: 980px) {
        
        .card-container {
            --column-count: 4;
        }
        
    }

    // mobile

    @media screen and (max-width: 650px) {
        .card-container {
            --column-count: 1;
            --spacing: 0;
            row-gap: 20px;
            
            .kt-widgets-container {
                grid-template-columns: auto;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 980px) {

        // &.kt-dashboard-page {
        //     position: absolute;
        //     top: 50px;
        //     bottom: 0;
        //     left: 0;
        //     right:0;

        // }

        .card-container {
            .card {
                .card-head {
                    grid-template-columns: auto;
                }

                .kt-button-wrapper {
                    
                    div {
                                            
                        button {                    
                            margin-left:0;
                            margin-right: 2px;
                        }
                    }
                }
            }

            margin-bottom: 20px;
        }

        .card.half {
            grid-column-end: span 1;                
        }

        h2 {
            font-size: 1.2em !important;            
        }       
        
    }
}