@import "colors.scss";


  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }


.kt-form-preview {

    width: 100%;

    .MuiFormControlLabel-asterisk {
        display: none !important;
    }

    h3 {
        text-align: center;
    }

    .kt-form-rendering {

        position: absolute;
        top: 70px;
        bottom: 0;
        left:0;
        right: 0;

        box-shadow: 5px 5px 10px gray;

        background-color: $almost-white;

        .kt-form-rows-scroll {
            height: 100%;
            overflow-y: auto;

            .kt-form-rows {

                animation: fadeIn 1s ease-in;

                display: flex;
                flex-direction: column;

                background-color: white;
                width: 800px;
                min-height: 600px;

                margin: 20px auto 20px auto;

                border-radius: 10px;
                box-shadow: 2px 2px 10px rgb(163, 163, 163);

                padding-left: 20px;
                padding-right: 55px;
                padding-top: 10px;
                padding-bottom: 50px;


                .kt-client-location-logo {
                    max-width: 200px;
                    margin: 20px auto 30px auto;
                    text-align: center;
                }

                .kt-form-row {
                    position: relative;
                    display: flex;
                    align-items:center;

                    margin: 5px 0;

                    user-select: none;

                    label {
                        color: $font-color;
                    }

                    .Mui-focused {
                        color: $font-color;
                    }

                    .kt-formHeadline-0, .kt-formHeadline-1, .kt-formHeadline-2, h2, h3, h4, label {
                        margin: 0 !important;
                        padding: 0 !important;
                        color: $font-color;
                    }

                    .kt-formHeadline-0 {
                        font-size: 1.2em;
                        margin-top: 1.2em !important;
                    }

                    .kt-formHeadline-1 {
                        font-size: 1.8em;
                        font-weight: 500;
                        margin-top: 1.8em !important;
                    }

                    .kt-formHeadline-2 {
                        font-size: 2.5em;
                        //font-weight: 900;

                        margin-top: 2.5em !important;
                    }



                    input, textarea {
                        border: none;
                        border-radius: 5px;
                        outline: none;
                        width: 100%;
                        padding: 5px;
                        color: $font-color;
                    }

                    input {
                        height: 30px;
                    }

                    input[type=checkbox], input[type=radio] {
                        height: auto;
                    }

                    .kt-inline-input-wrapper {
                        display: flex;
                        align-items: flex-start;
                    }

                    .kt-input-text-placeholder {
                        height: 30px;
                        width: 100%;
                        border: 1px solid $font-color;
                        border-radius: 3px;
                    }

                    .kt-form-row-content {
                        width: 100%;

                        margin-left: 30px;
                    }


                    .kt-placeholder {
                        min-height: 50px;
                    }


                    .kt-form-items {
                        display: flex;
                        min-height: 20px;
                        //column-gap: 5px;

                        .kt-form-item {
                            width: 100%;
                            position: relative;

                            display: grid;

                            align-items: center;
                            margin: 5px;

                            .MuiFormLabel-root {
                                color: $form-font-color;
                            }
                            .kt-form-input-wrapper {
                                background-color: #edf5fc;
                                padding: 10px;
                                border-radius: 5px;

                                &.kt-white-input {
                                    .MuiFormControl-root, .MuiSelect-select:focus, label {
                                        background-color: white !important;
                                    }
                                }
                            }

                            &.kt-form-item-selected {
                                border-color: $primary-color;
                            }

                            .MuiFormControl-root {
                                width: 100%;
                            }

                            .kt-form-item-content {
                                padding-right: 10px;

                                .kt-form-item {
                                    width: 95%;

                                    .kt-form-answer {
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }

                            .kt-form-item-content {

                                .kt-form-item-checkbox {
                                    display: grid;
                                    grid-template-columns: 32px auto;
                                    align-items: center;
                                }

                                .mce-content-body {
                                    cursor: auto;
                                }
                            }


                        }
                    }
                }

            }

            Button {
                display: block;
                width: 200px;
                margin: auto;
                margin-bottom: 20px;
            }

            .kt-keyboardDatePicker Button {
                width: auto;
                margin: 0;
            }


        }


        &.kt-form-rendering-small {
            position: relative;
            top: 0;
            background-color: white;
            box-shadow: none;

            .kt-form-rows-scroll {

                .kt-form-rows {
                    width: 500px;
                    min-height: 300px;
                }
            }

            .kt-button-wrapper{
                display: flex;
            }
        }


    }

}


.kt-signature {
    display: inline-block;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    width: 190px;
    color: lightgray;
    text-align: center;
    margin-bottom: 5px;

    i {
        font-size: 30px;
        display: block;
    }

    span {
        font-size: 14px;
    }

    img {
        width: 120px;
    }

    &:hover {
        color: $secondary-color;
        border-color: $secondary-color;
        cursor: pointer;
    }
}

@media screen and (max-width: 1024px) {

    .kt-form-preview {

        .kt-form-rendering {

            .kt-form-rows-scroll {

                .kt-form-rows {
                    width: 100%;
                    padding-right: 20px;

                    .kt-imageFormItemCtrl {
                        img {
                            max-width: 500px !important;
                        }
                    }

                    .kt-videoFormItemCtrl {
                        img {
                            max-width: 500px !important;
                        }
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 800px) {

    .kt-form-preview {

        .kt-form-rendering {

            .kt-form-rows-scroll {

                .kt-form-rows {
                    width: 100%;
                    padding-right: 20px;

                    .kt-form-row {
                        .kt-form-row-content {
                            margin: 0 !important;

                            .kt-form-items {
                                flex-direction: column;

                                .kt-form-item {
                                    margin: 5px 0 !important;
                                }

                                .kt-form-item-content {
                                    padding: 0 !important;

                                    .kt-formHeadline-1 {
                                        font-size: 1.4em;
                                    }

                                    .kt-formHeadline-0 {
                                        font-size: 1.1em;
                                    }

                                    .kt-formHeadline-1 {
                                        font-size: 1.4em;
                                    }

                                    .kt-formHeadline-2 {
                                        font-size: 1.6em;
                                    }
                                }
                            }
                        }
                    }

                    .kt-imageFormItemCtrl {
                        img {
                            max-width: 250px !important;
                        }
                    }

                    .kt-videoFormItemCtrl {
                        iframe {
                            max-width: 100% !important;
                            max-height: 300px;
                        }
                    }
                }
            }
        }
    }

}