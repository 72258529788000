.customTourTooltip {
    border: 1px solid $primary-color;
    border-radius: 10px !important;

    .introjs-nextbutton {
        color: $primary-color !important;
        font-weight: 600;
        border-color: $primary-color !important;
    }
}

.introjs-helperlayer {
    max-height: 400px !important;
    height: 400px !important;
}


