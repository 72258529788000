.kt-before-after-images-upload-dlg {

    .kt-before-after-row {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto 64px;

        padding-bottom: 5px;
        border-bottom: 1px solid lightgray;

        img {
            width: 200px;
        }
    }
}