@import "colors.scss";

.kt-form-editor {

    .kt-form-rendering {
        position: relative;
        height: 100%;
        box-shadow: 5px 5px 10px gray;

        display: grid;
        grid-template-columns: 250px auto 335px;
        column-gap: 10px;

        background-color: $almost-white;

        .kt-edit-form-tool-box {

            background-color: $secondary-color;
            color: $almost-white;
            padding: 0;

            display: flex;
            flex-direction: column;

            .kt-edit-form-tool-box-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 45px;
                border: 1px solid transparent;
                border-bottom: 1px solid $secondary-color;
                background-color: $secondary-color-light;
                transition: background-color 500ms;
                user-select: none;

                i {
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $secondary-color;
                    font-size: 25px;
                    margin-right: 5px;
                    width: 45px;
                    height: 45px;
                    border-bottom: 1px solid $secondary-color-light;

                    transition: background-color 1000ms;

                }

                &:hover {
                    background-color: $primary-color;
                    cursor: hand;

                    i {
                        background-color: $primary-color;

                    }
                }

                &.kt-clone ~ div {
                    transform: none !important;
                }
            }

            .kt-tool-box-group-label {
                font-weight: 700;
                margin-bottom: 5px
            }

            button {
                margin-bottom: 5px;
            }
        }

        .kt-form-rows-scroll {
            max-height: 800px;
            overflow-y: auto;

            .kt-form-rows {

                background-color: white;
                width: 800px;
                min-height: 600px;

                margin: 20px auto 20px auto;

                border-radius: 10px;
                box-shadow: 2px 2px 10px rgb(163, 163, 163);

                padding-left: 20px;
                padding-right: 55px;
                padding-top: 10px;
                padding-bottom: 50px;

                .kt-form-row-wrapper {
                    position: relative;

                    &.kt-dummy-row {
                        height: 400px;
                        background-color: rgb(236, 235, 235);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .kt-drop-area-top {
                            height: 100%;
                        }

                        @keyframes slidein {
                            from {
                                top: -100px;
                              left: -200px;
                              color: $primary-color;
                              transform: rotate(-45deg);
                            }

                            to {
                              left: -60px;
                              color: $secondary-color;
                              opacity: 1;
                            }
                          }

                        p {
                            text-align: center;
                            position: relative;

                            i {
                                font-size: 3rem;
                                position: absolute;
                                opacity: 0;

                                top: -15px;
                                left: -60px;

                                animation: slidein 2s 1s forwards;
                            }
                        }
                    }


                    .kt-drop-area-top, .kt-drop-area-bottom {
                        position: absolute;
                        height: 10px;
                        width: 100%;
                        border: 0;
                        z-index: 10;
                        pointer-events: none; // will be set later to "all" on "drag enter"
                    }

                    .kt-drop-area-top {
                        top: -3px;
                        border-top: 2px solid transparent;
                        &.kt-on-drag-enter {
                            border-top: 2px solid $primary-color;
                        }
                    }
                    .kt-drop-area-bottom {
                        bottom: -3px;
                        border-bottom: 2px solid transparent;
                        &.kt-on-drag-enter {
                            border-bottom: 2px solid $primary-color;
                        }
                    }


                    .kt-form-row {
                        position: relative;
                        display: flex;
                        align-items:center;

                        min-height: 40px;

                        border: 1px dashed lightgray;
                        border-radius: 5px;

                        margin: 4px 0;

                        user-select: none;

                        .kt-row-gripper {
                            position: absolute;
                            height: 100%;
                            width: 22px;
                            text-align: center;
                            border-right: 1px solid lightgray;
                            display: flex;
                            align-items: center;
                            color: lightgray;
                            padding-left: 3px;
                            cursor: grab;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        label {
                            color: $font-color;
                        }

                        .Mui-focused {
                            color: $font-color;
                        }

                        .kt-formHeadline-0, .kt-formHeadline-1, .kt-formHeadline-2, h2, h3, h4, label {
                            margin: 0 !important;
                            padding: 0 !important;
                            color: $font-color;
                        }

                        .kt-formHeadline-0 {
                            font-size: small;
                        }

                        .kt-formHeadline-1 {
                            font-size: medium;
                            font-weight: 600;
                        }

                        .kt-formHeadline-2 {
                            font-size: larger;
                            font-weight: 900;
                        }


                        &:hover {
                            border: 1px dashed gray;
                            cursor: hand;

                            .kt-row-gripper {
                                color: $primary-color;
                            }
                        }
                        &.kt-form-row-selected {
                            //border: 1px solid $primary-color;
                            .kt-row-gripper {
                                color: $primary-color;
                            }
                        }
                        &.kt-form-row-dragging {
                            border: 1px dashed $primary-color;
                            .kt-row-gripper {
                                color: $primary-color;
                            }
                        }

                        input, textarea {
                            border: none;
                            border-radius: 5px;
                            outline: none;
                            width: 100%;
                            padding: 5px;
                            color: $font-color;
                        }

                        input {
                            height: 30px;
                        }

                        input[type=checkbox], input[type=radio] {
                            height: auto;
                        }

                        .kt-inline-input-wrapper {
                            display: flex;
                            align-items: flex-start;
                        }

                        .kt-input-text-placeholder {
                            height: 40px;
                            width: 100%;
                            border: 1px solid $font-color;
                            border-radius: 3px;
                        }

                        .kt-form-row-content {
                            width: 100%;

                            margin-left: 30px;
                        }

                        .kt-row-edit-buttons{
                            position: relative;
                            right: -35px;
                            color: lightgray;
                            font-size: 10px;

                            div {
                                padding: 2px;
                            }

                            &:hover {
                                color: $primary-color;
                                cursor: pointer;
                            }
                        }

                        .kt-signature {
                            border: 1px solid lightgray;
                            border-radius: 5px;
                            padding: 10px;
                            width: 150px;
                        }

                        .kt-placeholder {
                            min-height: 50px;
                        }


                        .kt-form-items {
                            display: flex;
                            min-height: 20px;

                            .kt-form-item {
                                width: 100%;
                                position: relative;
                                border: 1px solid rgb(233, 231, 231);
                                display: grid;
                                grid-template-columns: 30px auto 30px;
                                align-items: center;
                                margin: 5px;

                                &.kt-form-item-answer {
                                    grid-template-columns: 30px auto 60px;
                                }

                                &.kt-dummy-column {
                                    min-height: 30px;
                                    .kt-drop-area-left {
                                        width: 100% !important;
                                    }
                                }
                                &.kt-form-item-selected {
                                    border-color: $primary-color;
                                }

                                $offset: 7px;

                                .kt-drop-area-left, .kt-drop-area-right {
                                    position: absolute;
                                    width: 10px !important;
                                    height: 100%;
                                    border: 0;
                                    z-index: 10;
                                    pointer-events: none; // will be set later to "all" on "drag enter"
                                }
                                .kt-drop-area-left {
                                    left: -$offset;
                                    border-left: 2px solid transparent;
                                    &.kt-on-drag-enter {
                                        border-left: 2px solid $primary-color;
                                    }
                                }
                                .kt-drop-area-right {
                                    right: -$offset;
                                    border-right: 2px solid transparent;
                                    &.kt-on-drag-enter {
                                        border-right: 2px solid $primary-color;
                                    }
                                }


                                .kt-form-item-content {
                                    padding-right: 10px;

                                    .kt-form-item {
                                        width: 95%;

                                        &.kt-form-item-answer {

                                            .kt-form-answer {

                                                .kt-answer-control{
                                                    display: flex;
                                                    align-items: center;
                                                }

                                                .kt-form-rows {
                                                    min-height: auto;
                                                    height: auto;
                                                    width: auto;
                                                    box-shadow: none;
                                                    border-radius: 0;
                                                    padding: 0;
                                                    margin:0;

                                                    .kt-row-edit-buttons {
                                                        right: 5px;
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                                .kt-item-gripper {
                                    //position: absolute;
                                    text-align: center;
                                    height: 100%;
                                    width: 22px;
                                    padding: 3px;
                                    text-align: center;
                                    border-right: 1px solid lightgray;
                                    display: flex;
                                    align-items: center;
                                    color: lightgray;
                                    cursor: grab;
                                }

                                .kt-form-item-content {

                                    .kt-form-item-checkbox {
                                        display: grid;
                                        grid-template-columns: 32px auto;
                                        align-items: center;
                                    }

                                    .mce-content-body {
                                        cursor: auto;

                                        &.mce-edit-focus {
                                            max-height: 400px;
                                            overflow-y: auto;
                                        }
                                    }
                                }

                                .kt-item-edit-buttons {
                                    color: lightgray;
                                    text-align: center;
                                    border-left: 1px solid lightgray;

                                    font-size: 10px;
                                    height: 100%;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    i {
                                        padding: 5px;
                                    }
                                    i:hover {
                                        color: $primary-color;
                                        cursor: pointer;
                                    }
                                }

                                &:hover {
                                    .kt-item-gripper {
                                        color: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                }


                &.kt-is-dragging-row {
                    .kt-form-row-wrapper {
                        input, textarea {
                            pointer-events: none;
                        }
                        .kt-drop-area-top, .kt-drop-area-bottom {
                            pointer-events: all;
                        }
                    }
                }

                &.kt-is-dragging-item {
                    input, textarea, .mce-content-body {
                        pointer-events: none;
                    }
                    .kt-form-items {
                        .kt-form-item {
                            .kt-drop-area-left, .kt-drop-area-right {
                                pointer-events: all !important;
                            }
                        }
                    }
                }


            }

        }


        .kt-form-row-properties {
            position: relative
            ;
            background-color: $secondary-color;
            color: $almost-white;
            padding: 20px;


            display: flex;
            flex-direction: column;

            .kt-window-buttons {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 27px;
                border-bottom: 1px solid white;

                display: flex;
                align-items: center;
                align-content: center;
                justify-content: right;

                i {
                    display: block;
                    margin-right: 10px;
                    cursor: pointer;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .kt-properties-label {
                margin-top: -18px;
            }

            .MuiFormControl-root, .MuiGrid-root {
                label, input, textarea, fieldset, .MuiButtonBase-root, .MuiTypography-root, .MuiFormHelperText-root {
                    color: $almost-white;
                    border-color: white;
                }

                .Mui-focused {
                    &.MuiFormLabel-root, fieldset {
                        color: $primary-color !important;
                        border-color: $primary-color !important;
                    }
                }

                .MuiButton-contained {
                    color: $secondary-color;
                }
            }

        }

    }


    &.kt-maximized {
        position: fixed;
        top:-45px;
        left:0;
        right:0;
        bottom: 0;
        z-index: 99;

        .kt-form-rendering {
            position: relative;

            .kt-form-rows-scroll {
                position: relative;
                max-height: 90%;

                .kt-form-rows {
                    height: auto;
                }

            }
        }
    }

}


.tox-tinymce--toolbar-sticky-off {
    z-index: 99999 !important;
}
