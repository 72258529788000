.kt-clonr-menu {
    grid-area: menu;

    .kt-paper {
        display: grid;
        grid-template-rows: 100px auto 270px;
        padding: 10px !important;
        background: linear-gradient(180deg, #24374e 10%, #c790c0 100%);
        overflow: hidden;

        .kt-menu-item {
            height: 35px;
            font-size: 16px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            text-decoration: none;
            color: white;
            cursor: pointer;

            i {
                margin: 0 8px;
            }

            &:hover,
            &.kt-selected {
                background-color: white;
                color: $primary-color;
            }
        }

        .kt-menu-credit-balance {
            margin-bottom: 20px;
            border-bottom: 1px solid $secondary-color;
            padding-bottom: 10px;
            color: white;
            text-align: center;            
        }

        >div {
            padding: 5px;

            i {
                width: 15px;
                margin-right: 5px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .kt-clonr-menu {
        display: none;
    }
}