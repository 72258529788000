.kt-clonr-voice-list-ctrl {

    .kt-voices-list-wrapper {

        max-height: calc(100vh - 80px);
        overflow-y: auto;

        .kt-voices-list {

            position: relative;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            
            img,
            .kt-voice-icon {
                width: 128px;
                height: 128px;
                object-fit: cover;
                border-radius: 10px;
                border: 1px solid lightgray;
                color: $secondary-color;

                &.kt-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                }

                &:hover {
                    cursor: pointer;
                    border: 2px solid $primary-color;
                    color: $primary-color;
                }
            }
        }

    }
}