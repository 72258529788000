@import "colors.scss";

.kt-data-table {

    display: block;
    height: 800px;
    
    width: 100%;
    border-spacing: 0;
    border-top: 1px solid $control-color;    
    border-collapse: collapse;
    table-layout: fixed;

    tbody tr,
    thead tr {

        display: flex;
        width: 100%;
        height: 40px;

        th {
            color: $secondary-color;
        }


        td, th {
        
            width: 25%;

            padding: 5px;
            flex: 1 auto;
            word-wrap: break;
           
            border-collapse: collapse;

            //padding-left: 15px;

            .kt-table-color-box {
                width: 20px;
                height: 20px;

                border: 1px solid grey;
            }

            &.kt-btn-table-column {
                width: 40px;
            }

            &.kt-table-small-column {
                width: 30px;
            }
        }

    
    }

    thead {
        display: block;
        
        overflow-y: scroll;
    }

    tbody {
        display: block;
        
        overflow-y: scroll;
        max-height: 100%;

        tr {
            font-size: 0.9em;
            cursor: pointer;
        }

        tr:nth-child(even) {background: white;}
        tr:nth-child(odd) {background: $row-color;}

        tr:hover {
            background: $row-hover-color; 
            color:$row-hover-text-color;
        }
    }
 

}