@import "colors.scss";
@import "fonts.scss";

@import "animations.scss";

@import "topNavBar.scss";

@import "pages/pageContent.scss";
@import "pages/loginPage.scss";
@import "pages/loadingPage.scss";
@import "pages/calendarPage.scss";
@import "pages/patientsPage.scss";
@import "pages/mapsPage.scss";
@import "pages/dashboardPage.scss";
@import "pages/videoCallPage.scss";
@import "pages/docsPage.scss";
@import "pages/documentPage.scss";
@import "pages/recallerPage.scss";
@import "pages/disabledPage.scss";
@import "pages/clonRPage.scss";

@import "calendar.scss";
@import "dimensions.scss";
@import "datePicker.scss";
@import "resourceFilter.scss";
@import "searchInput.scss";
@import "dialogs/dialog.scss";
@import "dialogs/documentsTab.scss";
@import "dialogs/signatureDialog.scss";
@import "dialogs/freeTimeSlotsCtrl/bookingPanel.scss";
@import "dialogs/freeTimeSlotsCtrl/freeTimeSlotsCtrl.scss";
@import "licenseCard.scss";
@import "avatar.scss";
@import "dataTable.scss";
@import "simpleList.scss";
@import "openingHoursInput.scss";
@import "multiAppointmentCtrl.scss";
@import "toaster.scss";
@import "waitingRoom.scss";
@import "visitMotivePicker.scss";
@import "videoRoom.scss";
@import "smsEditor.scss";
@import "chartControls.scss";
@import "starsCtrl.scss";
@import "starsInputCtrl.scss";
@import "fullCalendarCustom";
@import "imageInputCtrl";
@import "imageUploadCtrl";
@import "beforeAfterImagesUploadDlg";
@import "formEditor.scss";
@import "formPreview.scss";
@import "dialogs/imageDrawDialog.scss";
@import "logItemList.scss";
@import "videosSidebar.scss";
@import "tour.scss";
@import "dropzone.scss";
@import "clonrAvatarListCtrl.scss";
@import "clonrVoiceListCtrl.scss";
@import "clonrMenu.scss";
@import "clonrMobileMenu.scss";
@import "videoSelectorCtrl.scss";
@import "transferList.scss";
@import "firstStepsWizard.scss";
@import "creditsPanel.scss";
@import "trafficLightCtrl.scss";
@import "videoPlayerCtrl.scss";
@import "settingsSideMenu.scss";

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    text-align: left;
}

h1, h2, h3, h4 {
    color: $secondary-color;
}

.MuiToolbar-root{
    h2 {
        color: white !important;
    }
}

a, .kt-history-back-link, .kt-link {
    color: $link-color;
    cursor: pointer;
}

.kt-underline {
    text-decoration: underline !important;
}

.App, .kt-loading-page {
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    right:0;
    height: 100%; // needed for Safari
    //overflow: hidden;

    &.kt-video-sidebar {
        right: 300px;
    }

    /* mobile viewport bug fix */
    max-height: -webkit-fill-available;

    display: grid;
    grid-template-rows: $nav-bar-height auto;

    font-family: 'Roboto', sans-serif;
    color: $font-color;

    margin: 0;
    font-size: 1em;

}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.kt-button-selected {
    color: $primary-color;
    font-weight: 900;
}

// remove styling from iOS
// textarea,
// input.text,
// input[type="text"],
// input[type="number"],
// input[type="button"],
// input[type="submit"],
// .input-checkbox {
//     // -webkit-appearance: none;
//     // border-radius: 0;
//     // background-color: white;
// }


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.kt-button {
    border: none;
    border-radius: 3px;
    box-shadow: lightgray 1px 1px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}


.kt-save-btn {
    display: inline-flex;

    .kt-save-status {
        display: inline-block;
        margin: 5px;
        padding: 7px;
        font-size: 14px;
        text-align: center;

        i {margin-right: 5px;}
    }
}

.kt-btn {
    border: 1px solid $btn-color;
    border-radius: 3px;
    padding: 7px;
    margin: 5px;
    font-size: 14px;
    text-align: center;
    width: fit-content;

    display: inline-flex;
    align-items: center;

    &.kt-primary {
        border-color: $primary-color;
        a {
            color: $primary-color;
        }

        &:hover {
            background-color: $primary-color;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $btn-color;
        color: white;
        a,i {
            color: white !important;
        }
    }

    i {
        margin-right: 5px;
    }

    a {
        text-decoration: none;
    }

    &.kt-btn-selected {
        background-color: $btn-color;
        color: white;
        a,i {
            color: white !important;
        }
    }
}


.kt-btn-small {
    height: 30px;
    background-color: white;
    &:hover {
        background-color: $btn-color;
    }
}

.kt-btn-tiny {
    height: 20px;
    background-color: white;
    font-size: 12px;
    padding: 0 5px 0 5px;
    &:hover {
        background-color: $btn-color;
    }
}

a.kt-btn {
    text-decoration: none;
}

.kt-form-row-full {
    display: grid;
    grid-template-columns: auto;

    textarea {
        width: 100%;
    }
}

.kt-primary-hover:hover {
    color: $primary-color !important;
    cursor: pointer;
}

.kt-red-hover:hover {
    color: red !important;
    cursor: pointer;
}

.kt-patient-history-list {
    display: grid;
    grid-template-columns: auto;
    background-color: white;

    .kt-patient-history-list-row {
        display: grid;
        grid-template-columns: auto auto auto;
        border-bottom: 1px solid lightgrey;
        padding: 3px 10px 3px 10px;

        &.kt-header {
            font-weight: 800;
            color: $secondary-color;
        }

    }
}

.kt-link {
    color: $secondary-color;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.kt-uppercase { text-transform: uppercase;}

.kt-logo-svg-background {
    fill: $primary-color;
}

.kt-logo-svg-foreground {
    fill: white;
}


input.kt-btn-big, div.kt-btn-big {
    display: block;
    background-color: $btn-color !important;
    color: white;
    cursor: pointer;

    width: 240px;

    padding: 10px 10px 10px 10px;
    margin: 10px auto 10px auto;
    border-radius: 5px;

    font-size: 1.5em;
    text-align: center;
    padding-top: 10px;

    box-shadow: 2px 2px 5px grey;

    text-decoration-line: none;
    border: none;

    &:hover {
        opacity: 0.85;
    }
}

.kt-confirm-color {
    color: $confirm-color;
}


.kt-tabs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 100px));

    .kt-tab {
        cursor: pointer;
        color: $font-color;
        padding-left: 5px;
        padding-top: 3px;
        border: 1px solid $control-color-light;
        text-align: center;
        text-decoration: none;

        &:hover {
            background-color: $secondary-color-light;
            color: white;
        }

        &.kt-selected {
            background-color: $secondary-color;
            border-bottom-color: $primary-color;
            color: white;
        }
    }
}

.kt-margin-top-20 {
    margin-top: 20px;
}

.kt-padding-5 {
    padding: 5px;
}

.kt-progress-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(128,128,128,0.6);
    z-index: 200;

    display: grid;
    align-content: center;
    justify-content: center;

    .kt-progress-panel {
        width: 300px;
        height: 150px;
        background: white;
        border-radius: 5px;
        font-size: 20px;
        display: grid;
        text-align: center;
        line-height: 50px;
        align-content: center;

        i {
            color: $primary-color;
        }
    }
}

.kt-disabled {
    pointer-events: none;
    opacity: 0.4;
}

.kt-grey-page {
    background-color: $grey-page-background-color;
}

.kt-warning-color {
    color: orange;
}

.kt-error-color {
    color: red;
}

.kt-line-through {
    text-decoration: line-through;
}

.kt-vertical-align-top {
    vertical-align: top !important;
}

.kt-page-with-side-menu {
    overflow-y: auto;
}

.kt-page {

    width: 100%;
    height: 100%;;
    padding: 15px;
    overflow-y: auto;


    .MuiGrid-spacing-xs-4 {
        width: 100%;
    }

    .MuiDataGrid-columnsContainer {
        background-color: $secondary-color-light;
        color: white;
    }

    .MuiDataGrid-row {
        &.Mui-odd {
            background-color: rgb(250, 250, 250);
        }
    }

    .kt-form-section {
        margin-bottom: 10px;

        h3, h4 {
            margin-top: 0;
        }
    }

    .kt-page-footer {
        .MuiButtonBase-root{
            margin-right: 10px;
        }
    }

    .kt-tabs {
        margin-top: 20px;
        margin-bottom: -16px;
    }
}

.kt-table-color-box {
    width: 20px;
    height: 18px;

    border: 1px solid grey;
    border-radius: 4px;
}

.kt-form-section {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $control-color;

    &.kt-bold-labels .MuiTypography-body1{
        font-weight: 600;
    }
}


.kt-data-grid-container {
    margin-top: 15px;
    width: 100%;
    height: calc(100% - 185px);
}

.kt-color-picker {
    height: 30px;
    width: 40px;
    margin-left: 5px;
    &:hover {
        cursor: pointer;
    }
}

.kt-primary-color {
    color: $primary-color !important;
}
.kt-secondary-color {
    color: $secondary-color !important;
}

.kt-border-primary-color {
    border-color: $primary-color !important;
}

.MuiListSubheader-root {
    color: $primary-color !important;
}

.kt-row-comment, .kt-text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.kt-message-text {
    font-family: 'Courier New', Courier, monospace;
}

.kt-red-color {
    color: red;
}

.kt-green-color {
    color: rgb(1, 193, 55);
}

.kt-orange-color {
    color: rgb(255, 136, 0);
}

.kt-no-text-decoration {
    text-decoration: none;
}

.kt-hidden {
    display: none;
}

.kt-text-center {
    text-align: center;
}

.kt-logo-upload {
    display: flex;
    align-items: center;
}

.kt-logo {
    border: 1px solid lightgray;
    padding: 5px;
    margin-right: 10px;
    min-height: 20px;
    width: 200px;
}


.kt-margin-bottom-10 {
    margin-bottom: 10px;
}

// appointments, documents..
.kt-content-rectangle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: white;
    background-color: $secondary-color;
    border: 1px solid $appointment-border-color;

    margin-bottom: 5px;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;

    border-left: 4px solid $secondary-color;
    border-radius: 4px;

    p {
        color: white !important;
    }
}

.kt-full-height {
    height: 100%;
}

.kt-data-grid-row-error {
    background-color: rgb(241, 192, 192);
    &:hover {
        background-color: rgb(247, 123, 123) !important;
    }
}

.MuiAccordionSummary-expandIconWrapper svg {
    color: white;
}

.kt-number-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: $secondary-color;
    color: white;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    margin-right: 20px;
}

// desktop
@media screen and (min-width: 981px) {
    .kt-hide-on-desktop {
        display: none !important;
    }
}

// mobile
@media screen and (max-width: 980px) {

    .App {
        &.kt-video-sidebar {
            right: 0;
        }

        .kt-videos-sidebar {
            display: none;
        }
    }

    .kt-hide-on-mobile {
        display: none !important;
    }

    .kt-form-row {

        >span {
            margin-top: -30px;
            margin-left: 20px;
        }
        div.kt-double-input-column, div.kt-double-input-column-150 {
            grid-template-columns: auto;
            grid-row-gap: 3px;
        }
    }

    .kt-mobile-no-text-button {
        .MuiButton-startIcon {
            margin: 0 !important;
        }
    }
}

    