.kt-stars-input-ctrl {
    .kt-stars-wrapper {
        font-size: 15px;

        color: orange;
        display: inline-block;
        margin-right: 10px;

        i {
            margin-right: 5px;
        }
    }

    i {
        cursor: pointer;

        &:hover {
            color: $primary-color;
        }
    }
}