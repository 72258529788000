.kt-simple-list {
    
    //display: inline-block;
    //width: 300px;
    margin-bottom: 10px;
    
    .kt-items-wrapper {
        div {
            display: grid;
            grid-template-columns: auto 20px;
            margin-bottom: 5px;
            border-bottom: 1px solid lightgray;

            .kt-delete-item {
                justify-self: end;                
            }
        }
    }

    .kt-input-column {
        display: grid;
        grid-template-columns: auto 110px;
        column-gap: 5px;

        input {
            width: inherit !important;
        }
    
        .kt-btn {
            margin: 0;
            padding: 0 7px 0 7px;
            width: 110px;
            text-align: center;
        }
    }
    
}