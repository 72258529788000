﻿@import "../colors.scss";

$dialogWidth: 1210px;
$borderRadius: 5px;

.kt-dialog {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(128,128,128,0.6);

    display: grid;

    .kt-appointment-qrcode-wrapper {
        position: absolute;
        top: -200px;
        left: -200px;
    }

    .kt-dialog-wrapper {
        position: absolute;
        justify-self: center;

        min-width: $dialogWidth;
        top: 20px;
        bottom: 20px;


        form {
            display: grid;
            grid-template-rows: 50px 40px auto 50px;
            height: 100%;

            .kt-show-mobile {
                display: none;
            }

            .kt-dialog-header {
                background-color: $secondary-color;
                border-top-left-radius: $borderRadius;
                border-top-right-radius: $borderRadius;
                color: white;
                display: grid;
                align-items: center;
                grid-template-columns: auto 30px;

                span {
                    //display: block;
                    text-align: center;
                    margin: auto;
                    font-size: 18px;

                    i {
                        margin-right: 8px;
                    }
                }

                div:hover {
                    cursor: pointer;
                    color: $primary-color;
                }
            }

            .kt-dialog-tab-buttons {
                background-color: white;
                display: grid;
                grid-auto-flow: column;

                div {
                    text-align: center;
                    padding-top: 10px;
                    cursor: pointer;

                    &.kt-selected-tab {
                        font-weight: 600;
                        border-bottom: 2px solid $primary-color;
                    }
                }
            }

            .kt-dialog-body {
                background-color: $dialog-body-color;
                padding: 20px;
                overflow-y: auto;
                display:grid;
                grid-template-columns: 220px auto 220px;
                height: 100%;

                &.kt-full-width {
                    grid-template-columns: auto;
                }

                .kt-dialog-inner-body {

                    //max-height: 400px;
                    // background-color: white;
                    // padding: 20px;
                    // border-radius: 5px;
                    height: 100%;
                    overflow-y: auto;
                    bottom: 0;
                    position: relative;

                    .kt-tab-page {
                        display: grid;
                        //grid-row-gap: 10px;
                    }

                    .kt-dialog-details-grid {
                        background-color: white;
                        border: 1px solid $input-border-color;
                        border-top: none;
                        border-radius: 3px;

                        .kt-border-left {
                            border-left: 1px solid $input-border-color;
                        }
                    }

                }

                .kt-action-buttons {

                    text-align: left;

                    button, .kt-button {
                        text-align: left;;
                        display: block;
                        height: 35px;
                        cursor: pointer;
                        background-color: white;
                        border: 1px solid $btn-color;
                        margin-top: 5px;
                        border-radius: 4px;

                        font-size: 13px;
                        &:hover {
                            color: $primary-color;
                        }

                        i {
                            margin: 0 5px 0 5px;
                        }
                    }

                    .kt-button {
                        padding-left: 5px;
                        padding-top: 6px;
                        text-decoration: none;
                        color: black;
                        &:hover {
                            opacity: 1;
                        }

                        &.kt-primary {
                            color: white;
                            background-color: $primary-color;
                            box-shadow: none;
                        }
                    }

                    .kt-delete-button {
                        color: red;
                    }
                }

                .kt-dialog-left-panel {
                    position: relative;
                    height: 100%;
                    overflow-y: auto;

                    ul {
                        margin-right: 10px;

                        li {
                            .MuiListItemText-root span{
                                font-size: 12px !important;
                            }
                        }
                    }

                }

                .kt-dialog-right-panel {

                    background-color: $secondary-color;
                    margin-left: 10px;
                    padding: 15px;
                    border-radius: 5px;

                    display: grid;
                    align-content: start;

                    .kt-qrcode-wrapper {
                        margin: auto;
                        background-color: white;
                        padding: 5px 5px 0 5px;
                    }
                    .kt-label {
                        color: white;
                        font-size: 18px;
                    }

                    .kt-row-spacer {
                        height: 30px;
                    }

                    .kt-row-spacer-20 {
                        height: 20px;
                    }

                    .kt-row-spacer-10 {
                        height: 10px;
                    }

                    .kt-module-logo {
                        width: 180px;
                        position: absolute;
                        bottom: 70px;
                    }

                    .kt-info-text {
                        color: white;
                        font-size: 12px;
                        position: absolute;
                        bottom: 90px;
                    }

                }
            }

            .kt-dialog-footer {
                background-color: white;
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
                border-top: 1px solid $input-border-color;
                //font-size: 14px;
                // display: grid;
                // grid-template-columns: auto 150px 150px;

                text-align: right;
                padding-right: 10px;

                button {
                    margin: 6px;
                }

            }
        }
    }
}



@media screen and (max-width: 980px) {

    .kt-dialog {

        position: absolute;
        padding: 3px;

        .kt-dialog-wrapper {
            left:0;
            right: 0;
            top: 0;
            bottom: 0;
            min-width: auto;
            width: 100%;
            margin: 3px;

            form {
                .kt-dialog-body {
                    //grid-template-columns: auto;
                    display: block;
                    .kt-dialog-details-grid {
                        grid-template-columns: auto;
                    }

                    .kt-form-row {
                        grid-template-columns: auto;
                    }

                    .kt-show-mobile {
                        display: block;;
                    }

                    .kt-hide-mobile {
                        display: none;
                    }

                    .kt-action-buttons {

                        button, .kt-button {
                            width: 250px;
                            margin: auto;
                            margin-top: 5px;
                            text-align: center;
                        }
                    }
                }
            }

        }
    }


}

@media screen and (max-width: 1048px) {

    .kt-dialog {

        position: absolute;
        padding: 3px;

        .kt-dialog-wrapper {
            left:0;
            right: 0;
            top: 0;
            bottom: 0;
            min-width: auto;

            .kt-dialog-body {
                grid-template-columns: auto 240px !important;

                .kt-dialog-left-panel {
                    display: none;
                }

            }

        }
    }


}